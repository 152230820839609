<template>
  <el-drawer title="查看订阅组" :visible.sync="dialog" direction="rtl" size="35%" style="font-size: 20px; font-weight: 600">
    <div class="grouplist">
      <h2 style="
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        border-bottom: 1px solid #ccc;
                        margin: 0;
                        padding: 0 8px 8px 8px;
                      "></h2>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="GroupName" label="群组名称" width="450">
        </el-table-column>
        <el-table-column prop="operate" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleView(scope.row)">查看</el-button>
            <el-button type="text" @click="handleQrcode(scope.row)">分享</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="height: 50px"></div>
    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '5px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 99999,
    }">
      <el-button @click="dialog = false">取消</el-button>
    </div>
    <EditForm ref="editForm" />
    <el-dialog :append-to-body="true" :visible.sync="dialogVisible" width="450px" :before-close="handleClose">
      <div style="display: flex;">
        <div class="codebox">
          <h3 style="  font-weight: bolder;">请使用微信扫一扫</h3>

          <h3 style="margin-top: -120px; font-weight: bolder;">订阅 [ {{ titleName }} ] 组的签收信息</h3>
        </div>
        <vue-qr class="box" :text="codeValue" :logoScale="0.2" :size="200"></vue-qr>
      </div>
    </el-dialog>
  </el-drawer>
</template>
<script>
import EditForm from "./GroupRecording.vue";
import VueQr from "vue-qr";
export default {
  components: {
    EditForm,
    VueQr,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      timer: null,
      tableData: [],
      titleName: '',
      Cid: "",
      dialogVisible: false,
      codeValue: "",
    };
  },
  methods: {
    handleClose(done) {
      this.dialogVisible = false;

      if (this.loading) {
        return;
      }
    },

    handleView(row) {
      this.$refs.editForm.openForm(row.Id);
    },
    handleQrcode(row) {
      this.codeValue = row.QrCode;
      this.titleName = row.GroupName;
      this.dialogVisible = true;
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      clearTimeout(this.timer);
    },
    openForm(Cid) {
      this.dialog = true;
      console.log(Cid);
      this.Cid = Cid;

      this.$http
        .post("/BO/CustomerinfoGroup/GetGroupData", {
          Search: {
            SupplieId: Cid,
          },
        })
        .then((res) => {
          if (res.Success) {
            this.tableData = res.Data;
            console.log(this.tableData);
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.iconStyle {
  color: #409eff;
}

.form {
  position: relative;
  padding-right: 10px;
}

.el-form-item {
  margin-bottom: 22px;
  margin-left: 13px;
}

.codebox {
  font-size: 18px;
  margin: -20px 0 0 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

}

.box {
  display: flex;
  margin-top: 21%;
  width: 50%;
  margin-left: -75%;
}


/deep/.el-dialog {
  padding: 1vw;
  font-weight: bolder;
  height: 400px;
  border-radius: 8px;
}
</style>